<template>
  <v-card class="d-flex justify-center mb-6 mt-6" flat tile>
    <v-card class="pa-2">
      <v-card-title> 打卡報表 </v-card-title>
      <v-container>
        <v-row>
          <v-col class="col-12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="targetDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="targetDate"
                  label="請選擇月份"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="targetDate"
                type="month"
                locale="zh-tw"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(targetDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn color="primary" @click="onBtnSend" :disabled="this.loading"
              >送出</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-progress-linear
              color="light-blue"
              height="25"
              value="10"
              v-model="progress"
              striped
              :indeterminate="progress == 0"
              v-show="this.loading"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn v-show="isDone" color="success" @click="onBtnSave">
              檔案下載
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>

<script>
import axios from "axios";
import ExcelJS from "exceljs";
// import moment from "moment";
import { saveAs } from "file-saver";
export default {
  name: "WorkTime",
  data() {
    return {
      isDone: false,
      loading: false,
      employees: [],
      total: 0,
      menu: false,
      blobUrl: null,
      targetDate: null,
      apiUrl:
        "https://oh1lgt8mi0.execute-api.ap-northeast-1.amazonaws.com/Prod/timeman/monthlyReport",
    };
  },
  mounted() {
    this.targetDate = new Date().toJSON().substring(0, 7);
  },
  computed: {
    // 计算属性的 getter
    progress: function () {
      if (this.total == 0) return 0;
      return Math.floor((this.employees.length / this.total) * 100);
    },
  },
  methods: {
    async onBtnSend() {
      if (!this.targetDate) return;
      this.loading = true;
      this.isDone = false;
      try {
        const year = this.targetDate.substring(0, 4);
        const month = this.targetDate.substring(5);
        let start = 0;
        this.employees = [];
        await this.getEmployeeWorkTime(start, year, month);
        this.isDone = true;
      } catch (err) {
        console.log(err);
        alert("伺服器發生錯誤，請重試！");
      }
      this.loading = false;
    },
    async onBtnSave() {
      await this.makeExcel();
    },
    async getEmployeeWorkTime(start, year, month) {
      await axios
        .get(this.apiUrl + `?start=${start}&year=${year}&month=${month}`)
        .then(async (res) => {
          let reponse = res.data;
          this.total = reponse.total;
          this.employees.push(...reponse.data);
          if (reponse.next) {
            await this.getEmployeeWorkTime(reponse.next, year, month);
          }
        });
    },
    async makeExcel() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("報表");
      sheet.columns = [
        { header: "name", key: "name", width: 10 },
        { header: "device", key: "device", width: 10 },
        {
          header: "dtWhen",
          key: "time",
          width: 25,
          style: { numFmt: "yyyy/mm/dd hh:mm:ss" },
        },
        { header: "sCards", key: "id", width: 10 },
        { header: "type", key: "type", width: 10 },
      ];
      for (let employee of this.employees) {
        const name = employee.LAST_NAME + employee.NAME;
        const idNum = employee.UF_USR_1629088744460;
        if (!idNum) continue;

        for (let day of employee.days) {
          if (day.start) {
            sheet.addRow({
              id: idNum,
              name: name,
              device: 11,
              time: this.ConvertTime(day.start),
              type: 1,
            });
          }
          if (day.finish) {
            sheet.addRow({
              id: idNum,
              name: name,
              device: 11,
              time: this.ConvertTime(day.finish),
              type: 2,
            });
          }
        }
      }
      await workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `${this.targetDate}.xlsx`);
      });
    },
    ConvertTime(datetime) {
      return new Date(Date.parse(datetime) + 8 * 60 * 60 * 1000);
    },
  },
};
</script>

<style scoped>
</style>np